import B from '@components/admin/button';
import { ModalInfo, useModalStore } from '@store/modal.store';

function ModalContainer() {
    const modalStack = useModalStore((state) => state.modalStack);

    return (
        <>
            {modalStack.map((modal) => (
                <BaseModal key={modal.id} {...modal}></BaseModal>
            ))}
        </>
    );
}

function BaseModal({ type, message, onSuccess, onClose }: ModalInfo) {
    const handleClose = (e) => {
        e.preventDefault();
        onClose!();
    };

    const handleSuccess = (e) => {
        e.preventDefault();
        if (onSuccess) {
            onSuccess();
        } else {
            onClose!();
        }
    };

    return (
        <div className={`modal_con sf`}>
            <div className="modal_in">
                <p className="ft16r modal_tit txt_c">{message}</p>
                <div className="com_btn_list center">
                    <B.Button className="line" onClick={handleClose}>
                        취소
                    </B.Button>
                    <B.Button className="add" onClick={handleSuccess}>
                        확인
                    </B.Button>
                </div>
            </div>
        </div>
    );
}

export default ModalContainer;
